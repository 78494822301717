/* eslint-disable react-hooks/exhaustive-deps */
import { InputText } from "primereact/inputtext";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { showToast } from "utils/common";
import { OrderDataDoc } from "types/order";
import { EOrderPaymentStatus } from "utils/enum";
import OrderServices from "services/orders";
import { Dropdown } from "primereact/dropdown";
import { PAYMENT_STATUS_OPTIONS } from "utils/constant";
import { MultiSelect } from "primereact/multiselect";
import { InputNumber } from "primereact/inputnumber";
import { EventDataDoc } from "types/event";
import EventServices from "services/events";

const Details = (props, ref) => {
  const { data, reload, toast, onCancel, setLoading } = props;

  const emptyData: OrderDataDoc = {
    _id: undefined,
    id: undefined,
    full_name: "",
    phone_number: "",
    email: "",
    booking_seats: [],
    amount: 0,
    amount_ticket: 0,
    vouchers: "",
    event_id: undefined,
    event: undefined,
    seats_map: undefined,
    order_code: "",
    payment_status: EOrderPaymentStatus.PENDING,
    payment_at: "",
  };

  const [details, setDetails] = useState<OrderDataDoc>(emptyData);
  console.log(details, "details");

  const [events, setEvents] = useState<EventDataDoc[]>([]);
  const [selectedEvent, setSelectedEvent] = useState<EventDataDoc>();

  useImperativeHandle(ref, () => ({
    submit: () => {
      submit();
    },
  }));

  const renderAllBookedSeats = (rowData: OrderDataDoc | EventDataDoc) => {
    const { seats_map }: any = rowData;
    if (seats_map) {
      const flatSeats = seats_map?.seats?.flat().flat();
      return flatSeats;
    }
    return [];
  };

  // const renderBookedSeats = (rowData: OrderDataDoc) => {
  //   const { booking_seats, seats_map }: any = rowData;
  //   if (seats_map) {
  //     const flatSeats = renderAllBookedSeats(rowData);
  //     const seats = flatSeats
  //       .filter((item) => booking_seats?.includes(item.id))
  //       ?.map((item) => item.id);
  //     return seats;
  //   }
  //   return [];
  // };

  const onInputChange = (value, name) => {
    setDetails((state) => {
      const updatedDetails = { ...state, [name]: value };
      if (name === "booking_seats") {
        updatedDetails.amount_ticket = value.length;
      }
      return updatedDetails;
    });
  };

  const submit = async () => {
    try {
      setLoading(true);
      if (data) {
        await OrderServices.updateOrder({
          params: {
            id: data._id,
          },
          body: {
            ...details,
          },
        });
      } else {
        await OrderServices.createOrder({
          body: {
            ...details,
          },
        });
      }
      setLoading(false);
      showToast(toast, "success", "Order saved!");
      onCancel();
      reload();
    } catch (error) {
      setLoading(false);
      showToast(toast, "error", error.errors);
    }
  };

  useEffect(() => {
    if (data) {
      setDetails(data);
      if (data.event_id) {
        const event = events.find((i) => i._id === data.event_id);
        setSelectedEvent(event);
      }
    } else {
      setDetails(emptyData);
    }
  }, [data]);

  useEffect(() => {
    EventServices.getAll({ query: { populate: "yes" } }).then((resp) => {
      if (resp?.length) {
        setEvents(resp.map((i) => ({ ...i, seats_map: i?.seat })));
      }
    });
  }, []);

  return (
    <div className="grid">
      <div className="field col-12">
        <label htmlFor="title">Order Code</label>
        <InputText
          id="order_code"
          value={details.order_code}
          onChange={(e) => onInputChange(e.target.value, "order_code")}
          required
          autoFocus
        />
      </div>
      <div className="field col-12">
        <label htmlFor="title">Event</label>
        <Dropdown
          value={details?.event_id || null}
          options={events?.map((item) => ({
            label: item.name,
            value: item._id,
          }))}
          onChange={(e) => {
            onInputChange(e.value, "event_id");
            const event = events.find((i) => i._id === e.value);
            setSelectedEvent(event);
          }}
          optionLabel="label"
          placeholder="Event"
          showClear
          className="w-full"
        />
      </div>
      <div className="field col-12">
        <label htmlFor="title">Phone Number</label>
        <InputText
          id="phone_number"
          value={details.phone_number}
          onChange={(e) => onInputChange(e.target.value, "phone_number")}
          required
          autoFocus
        />
      </div>
      <div className="field col-12">
        <label htmlFor="title">Email</label>
        <InputText
          id="email"
          value={details.email}
          onChange={(e) => onInputChange(e.target.value, "email")}
          required
          autoFocus
        />
      </div>
      <div className="field col-12">
        <label htmlFor="title">Amount Ticket</label>
        <InputNumber
          id="amount_ticket"
          value={details.amount_ticket}
          onChange={(e) => onInputChange(e.value, "amount_ticket")}
          required
          autoFocus
        />
      </div>
      <div className="field col-12">
        <label htmlFor="title">Booking Seats</label>
        <MultiSelect
          id="booking_seats"
          value={details?.booking_seats}
          options={renderAllBookedSeats(selectedEvent ?? details)?.map((i) => ({
            label: i.name,
            value: i.id,
          }))}
          onChange={(e) => onInputChange(e.value, "booking_seats")}
          multiple
          placeholder="Chọn ghế"
          className="w-full"
        />
      </div>
      <div className="field col-12">
        <label htmlFor="title">Payment Status</label>
        <Dropdown
          value={details.payment_status || EOrderPaymentStatus.PENDING}
          options={PAYMENT_STATUS_OPTIONS}
          optionLabel="label"
          placeholder="Status"
          showClear
          className="w-full"
          onChange={(e) => onInputChange(e.value, "payment_status")}
        />
      </div>
    </div>
  );
};

export default forwardRef(Details);
