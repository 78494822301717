/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import classNames from "classnames";
import { Route, Switch, useLocation } from "react-router-dom";
import { CSSTransition } from "react-transition-group";

import { AppTopbar } from "../../layouts/AppTopbar";
import { AppFooter } from "../../layouts/AppFooter";
import { AppMenu } from "../../layouts/AppMenu";
import { AppConfig } from "../../layouts/AppConfig";

import PrimeReact from "primereact/api";
import { Tooltip } from "primereact/tooltip";
import { MasterContext } from "contexts/MasterContext";
import { useContext } from "react";

import Dashboard from "./dashboards";
import Empty from "./empty";
import Users from "./users";
import Roles from "./roles";
import Events from "./events";
import SeatMap from "./seats-map";
import TemplateSeatsMap from "./templates-seats-map";
import Orders from "./orders";
import Tickets from "./tickets";
import Blogs from "./blogs";
import Vouchers from "./vouchers";
import Revenue from "./statistics/revenue";
import AccountPage from "./settings/account";
import SystemPage from "./settings/system";
import EmailTemplates from "./settings/mail-templates";
import Menus from "./settings/menus";
import ThemesSetting from "./settings/event-themes";
import Categories from "./categories";
import Onepay from "./settings/onepay";

const App = () => {
  const [layoutMode, setLayoutMode] = useState("static");
  const [layoutColorMode, setLayoutColorMode] = useState("light");
  const [inputStyle, setInputStyle] = useState("outlined");
  const [ripple, setRipple] = useState(true);
  const [staticMenuInactive, setStaticMenuInactive] = useState(false);
  const [overlayMenuActive, setOverlayMenuActive] = useState(false);
  const [mobileMenuActive, setMobileMenuActive] = useState(false);
  const [mobileTopbarMenuActive, setMobileTopbarMenuActive] = useState(false);
  const copyTooltipRef: any = useRef();
  const location = useLocation();
  const { auth, getUsers, getLocations } = useContext(MasterContext);

  PrimeReact.ripple = true;

  let menuClick = false;
  let mobileTopbarMenuClick = false;

  useEffect(() => {
    if (auth) {
      getUsers();
      getLocations();
    }
  }, [auth]);

  useEffect(() => {
    if (mobileMenuActive) {
      addClass(document.body, "body-overflow-hidden");
    } else {
      removeClass(document.body, "body-overflow-hidden");
    }
  }, [mobileMenuActive]);

  useEffect(() => {
    copyTooltipRef &&
      copyTooltipRef.current &&
      copyTooltipRef.current.updateTargetEvents();
  }, [location]);

  const onInputStyleChange = (inputStyle: any) => {
    setInputStyle(inputStyle);
  };

  const onRipple = (e: any) => {
    PrimeReact.ripple = e.value;
    setRipple(e.value);
  };

  const onLayoutModeChange = (mode: React.SetStateAction<string>) => {
    setLayoutMode(mode);
  };

  const onColorModeChange = (mode: React.SetStateAction<string>) => {
    setLayoutColorMode(mode);
  };

  const onWrapperClick = (event: any) => {
    if (!menuClick) {
      setOverlayMenuActive(false);
      setMobileMenuActive(false);
    }

    if (!mobileTopbarMenuClick) {
      setMobileTopbarMenuActive(false);
    }

    mobileTopbarMenuClick = false;
    menuClick = false;
  };

  const onToggleMenuClick = (event: { preventDefault: () => void }) => {
    menuClick = true;

    if (isDesktop()) {
      if (layoutMode === "overlay") {
        if (mobileMenuActive === true) {
          setOverlayMenuActive(true);
        }

        setOverlayMenuActive((prevState) => !prevState);
        setMobileMenuActive(false);
      } else if (layoutMode === "static") {
        setStaticMenuInactive((prevState) => !prevState);
      }
    } else {
      setMobileMenuActive((prevState) => !prevState);
    }

    event.preventDefault();
  };

  const onSidebarClick = () => {
    menuClick = true;
  };

  const onMobileTopbarMenuClick = (event: { preventDefault: () => void }) => {
    mobileTopbarMenuClick = true;

    setMobileTopbarMenuActive((prevState) => !prevState);
    event.preventDefault();
  };

  const onMobileSubTopbarMenuClick = (event: {
    preventDefault: () => void;
  }) => {
    mobileTopbarMenuClick = true;
    event.preventDefault();
  };

  const onMenuItemClick = (event: { item: { items: any } }) => {
    if (!event.item.items) {
      setOverlayMenuActive(false);
      setMobileMenuActive(false);
    }
  };

  const isDesktop = () => {
    return window.innerWidth >= 992;
  };

  const menu = [
    {
      label: "Trang chủ",
      items: [
        {
          label: "Trang chủ",
          icon: "pi pi-fw pi-home",
          to: "/",
          module: "overview",
        },
      ],
    },
    {
      label: "Cài đặt sự kiện",
      items: [
        {
          label: "Mẫu",
          icon: "bx bxs-user",
          to: "/templates-seats-map",
          badge: "10",
          module: "events",
        },
        {
          label: "Chủ đề sự kiện",
          icon: "bx bxs-user",
          to: "/settings/event-themes",
          module: "settings",
        },
      ],
    },
    {
      label: "SỰ KIỆN",
      items: [
        {
          label: "Sự kiện",
          icon: "bx bxs-user",
          to: "/events",
          badge: "10",
          module: "events",
        },
        {
          label: "Sơ đồ chỗ ngồi cho sự kiện",
          icon: "bx bxs-user",
          to: "/seats-map-for-event",
          badge: "10",
          module: "seats-map",
        },
      ],
    },
    // {
    //   label: "ĐẶT VÉ",
    //   items: [
    //     {
    //       label: "Đặt vé",
    //       icon: "bx bxs-user",
    //       to: "/orders",
    //       badge: "10",
    //       module: "orders",
    //     },
    //   ],
    // },
    // {
    //   label: "VÉ",
    //   items: [
    //     {
    //       label: "Vé",
    //       icon: "bx bxs-user",
    //       to: "/tickets",
    //       badge: "10",
    //       module: "tickets",
    //     },
    //   ],
    // },
    {
      label: "NGƯỜI DÙNG",
      items: [
        {
          label: "Người dùng",
          icon: "bx bxs-user",
          to: "/users",
          badge: "10",
          module: "users",
        },
        {
          label: "Quyền",
          icon: "bx bxs-user",
          to: "/roles",
          badge: "10",
          module: "roles",
        },
      ],
    },
    // {
    //   label: "GIẢM GIÁ",
    //   items: [
    //     {
    //       label: "Mã giảm giá",
    //       icon: "bx bxs-user",
    //       to: "/vouchers",
    //       badge: "10",
    //       module: "vouchers",
    //     },
    //   ],
    // },
    {
      label: "BLOGS",
      items: [
        {
          label: "Blogs",
          icon: "bx bxs-user",
          to: "/blogs",
          badge: "10",
          module: "blogs",
        },
        {
          label: "Doanh mục",
          icon: "bx bxs-user",
          to: "/categories",
          badge: "10",
          module: "categories",
        },
      ],
    },
    // {
    //   label: "THỐNG KÊ",
    //   items: [
    //     {
    //       label: "Doanh thu",
    //       icon: "bx bxs-user",
    //       to: "/statistics/revenue",
    //       badge: "10",
    //       module: "revenue",
    //     },
    //   ],
    // },
    {
      label: "CÀI ĐẶT",
      icon: "pi pi-fw pi-sitemap",
      items: [
        {
          label: "Chân trang",
          icon: "pi pi-fw pi-bars",
          to: "/settings/menus",
          module: "settings",
        },
        // {
        //   label: "Hệ thống",
        //   icon: "pi pi-fw pi-info-circle",
        //   to: "/settings/system",
        //   module: "settings",
        // },
        // {
        //   label: "Onepay",
        //   icon: "pi pi-fw pi-info-circle",
        //   to: "/settings/onepay",
        //   module: "settings",
        // },
        // {
        //   label: "Email Templates",
        //   icon: "pi pi-fw pi-inbox",
        //   to: "/settings/email-templates",
        //   module: "settings",
        // },
        {
          label: "Tài khoản",
          icon: "bx bxs-user",
          to: "/settings/account",
          module: "settings",
        },

        // {
        //   label: "Menus",
        //   icon: "pi pi-fw pi-bars",
        //   to: "/settings/menus",
        //   module: "settings",
        // },
      ],
    },
  ];

  const addClass = (element: HTMLElement, className: string) => {
    if (element.classList) element.classList.add(className);
    else element.className += " " + className;
  };

  const removeClass = (element: HTMLElement, className: string) => {
    if (element.classList) element.classList.remove(className);
    else
      element.className = element.className.replace(
        new RegExp(
          "(^|\\b)" + className.split(" ").join("|") + "(\\b|$)",
          "gi"
        ),
        " "
      );
  };

  const wrapperClass = classNames("layout-wrapper", {
    "layout-overlay": layoutMode === "overlay",
    "layout-static": layoutMode === "static",
    "layout-static-sidebar-inactive":
      staticMenuInactive && layoutMode === "static",
    "layout-overlay-sidebar-active":
      overlayMenuActive && layoutMode === "overlay",
    "layout-mobile-sidebar-active": mobileMenuActive,
    "p-input-filled": inputStyle === "filled",
    "p-ripple-disabled": ripple === false,
    "layout-theme-light": layoutColorMode === "light",
  });

  return (
    <div className={wrapperClass} onClick={onWrapperClick}>
      <Tooltip
        ref={copyTooltipRef}
        target=".block-action-copy"
        position="bottom"
        content="Copied to clipboard"
        event="focus"
      />

      <AppTopbar
        onToggleMenuClick={onToggleMenuClick}
        layoutColorMode={layoutColorMode}
        mobileTopbarMenuActive={mobileTopbarMenuActive}
        onMobileTopbarMenuClick={onMobileTopbarMenuClick}
        onMobileSubTopbarMenuClick={onMobileSubTopbarMenuClick}
      />

      <div className="layout-sidebar" onClick={onSidebarClick}>
        <AppMenu
          model={menu}
          onMenuItemClick={onMenuItemClick}
          layoutColorMode={layoutColorMode}
        />
      </div>

      <div className="layout-main-container">
        <div className="layout-main">
          <Switch>
            <Route
              path="/"
              exact
              render={() => (
                <Dashboard colorMode={layoutColorMode} location={location} />
              )}
            />
            <Route path="/users" component={Users} />
            <Route path="/roles" component={Roles} />
            <Route path="/events" component={Events} />
            <Route path="/seats-map-for-event" component={SeatMap} />
            <Route path="/templates-seats-map" component={TemplateSeatsMap} />
            <Route path="/orders" component={Orders} />
            <Route path="/tickets" component={Tickets} />
            <Route path="/blogs" component={Blogs} />
            <Route path="/vouchers" component={Vouchers} />
            <Route path="/statistics/revenue" component={Revenue} />
            <Route path="/settings/account" component={AccountPage} />
            <Route path="/settings/system" component={SystemPage} />
            <Route path="/settings/onepay" component={Onepay} />
            <Route path="/settings/menus" component={Menus} />
            <Route path="/settings/event-themes" component={ThemesSetting} />
            <Route path="/categories" component={Categories} />

            <Route
              path="/settings/email-templates"
              component={EmailTemplates}
            />

            <Route path="*" component={Empty} />
          </Switch>
        </div>

        <AppFooter layoutColorMode={layoutColorMode} />
      </div>

      <AppConfig
        rippleEffect={ripple}
        onRippleEffect={onRipple}
        inputStyle={inputStyle}
        onInputStyleChange={onInputStyleChange}
        layoutMode={layoutMode}
        onLayoutModeChange={onLayoutModeChange}
        layoutColorMode={layoutColorMode}
        onColorModeChange={onColorModeChange}
      />

      <CSSTransition
        classNames="layout-mask"
        timeout={{ enter: 200, exit: 200 }}
        in={mobileMenuActive}
        unmountOnExit
      >
        <div className="layout-mask p-component-overlay"></div>
      </CSSTransition>
    </div>
  );
};

export default App;
