/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useImperativeHandle } from "react";
import { showToast } from "utils/common";
import { forwardRef } from "react";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import EventServices from "services/events";
import { Dropdown } from "primereact/dropdown";
import { InputNumber } from "primereact/inputnumber";
import { VoucherDataDoc } from "types/voucher";
import { COMMON_STATUS, EVENT_STATUS, VOUCHER_TYPE } from "utils/enum";
import VoucherServices from "services/vouchers";
import { EventDataDoc } from "types/event";
import {
  COMMON_STATUS_OPTIONS,
  HOURS,
  VOUCHER_TYPE_OPTIONS,
} from "utils/constant";
import { Checkbox } from "primereact/checkbox";

const Details = (props, ref) => {
  const { data, reload, toast, onCancel, setLoading } = props;

  const emptyData: VoucherDataDoc = {
    name: "",
    voucher_code: "",
    start_date: null,
    end_date: null,
    quantity: 0,
    percent: 0,
    status: COMMON_STATUS.ENABLE,
    event_id: null,
    type: VOUCHER_TYPE.PERCENT,
    is_min_order_value: false,
    min_order_value: 0,
    desc: "",

    is_max_order_value: false,
    max_order_value: 0,
    purchased_ticket_value: 0,
    gift_ticket_value: 0,

    is_order_value: false,
    order_value: 0,

    is_frame_time: false,
    start_time: 1,
    end_time: 23,
  };
  const [details, setDetails] = useState<VoucherDataDoc>(emptyData);
  const [events, setEvents] = useState<EventDataDoc[]>([]);

  useImperativeHandle(ref, () => ({
    submit: () => {
      submit();
    },
  }));

  useEffect(() => {
    if (data) {
      setDetails(data);
    }
  }, [data]);

  const onChange = (value, name, field = null) => {
    let _details = { ...details };
    if (field) {
      _details[field][`${name}`] = value;
    } else {
      _details[`${name}`] = value;
    }
    setDetails(_details);
  };

  const submit = async () => {
    try {
      setLoading(true);
      if (data) {
        await VoucherServices.updateVoucher({
          params: {
            id: data._id,
          },
          body: {
            ...details,
          },
        });
      } else {
        await VoucherServices.createVoucher({
          body: {
            ...details,
          },
        });
      }
      setLoading(false);
      showToast(toast, "success", "Voucher saved!");
      onCancel();
      reload();
    } catch (error) {
      setLoading(false);
      showToast(toast, "error", error.errors);
    }
  };

  useEffect(() => {
    EventServices.getAll().then((resp) => {
      if (resp?.length) {
        const events = resp?.filter(
          (item) => item?.status === EVENT_STATUS.COMING_SOON
        );
        setEvents(events);
      }
    });
  }, []);

  const isPercent = details.type === "percent";

  return (
    <div className="grid">
      <div className="field col-12 md:col-6">
        <label htmlFor="name">Name</label>
        <InputText
          id="name"
          value={details.name}
          onChange={(e) => onChange(e.target.value, "name")}
          required
          autoFocus
        />
      </div>
      <div className="field col-12 md:col-6">
        <label htmlFor="name">Code</label>
        <InputText
          id="name"
          value={details.voucher_code}
          onChange={(e) => onChange(e.target.value, "voucher_code")}
          required
        />
      </div>

      <div className="field col-12 ">
        <label htmlFor="name">Content</label>
        <InputText
          id="name"
          value={details.desc}
          onChange={(e) => onChange(e.target.value, "desc")}
          required
        />
      </div>

      <div className="field col-12 md:col-4">
        <label htmlFor="description">Status</label>
        <Dropdown
          value={details.status}
          options={COMMON_STATUS_OPTIONS}
          onChange={(e) => onChange(e.value, "status")}
          optionLabel="label"
          placeholder="Select status"
        />
      </div>
      <div className="field col-12 md:col-4">
        <label htmlFor="description">Type</label>
        <Dropdown
          value={details.type}
          options={VOUCHER_TYPE_OPTIONS}
          onChange={(e) => onChange(e.value, "type")}
          optionLabel="label"
          placeholder="Select type"
        />
      </div>

      <div className="field col-12 md:col-4">
        <label htmlFor="description">Event</label>
        <Dropdown
          value={details.event_id || ""}
          options={[
            {
              label: "All Events",
              value: "",
            },
            ...events.map((event) => ({
              label: event.name,
              value: event._id,
            })),
          ]}
          onChange={(e) => onChange(e.value, "event_id")}
          optionLabel="label"
          placeholder="Select event"
        />
      </div>
      <div className="field col-12 md:col-6">
        <label htmlFor="quantity">Amount Voucher</label>
        <InputNumber
          id="quantity"
          value={details.quantity}
          onChange={(e) => onChange(e.value, "quantity")}
          required
        />
      </div>
      {(details.type === "percent" || details.type === "amount") &&
        <div className="field col-12 md:col-6">
          <label htmlFor="percent">{isPercent ? "Percent" : "Amount"}</label>
          <InputNumber
            id="percent"
            value={details.percent}
            onChange={(e) => onChange(e.value, "percent")}
            required
            prefix={isPercent ? "%" : ""}
          />
        </div>
      }
      {details.type === "ticket" &&
        <>
          <div className="field col-12 md:col-6">
            <label htmlFor="purchased_ticket_value">Purchased ticket value</label>
            <InputNumber
              id="purchased_ticket_value"
              value={details.purchased_ticket_value}
              onChange={(e) => onChange(e.value, "purchased_ticket_value")}
              required
              prefix={""}
            />
          </div>
          <div className="field col-12 md:col-6">
            <label htmlFor="gift_ticket_value">Gift ticket value</label>
            <InputNumber
              id="gift_ticket_value"
              value={details.gift_ticket_value}
              onChange={(e) => onChange(e.value, "gift_ticket_value")}
              required
              prefix={""}
            />
          </div>
        </>
      }

      <div className="field col-12 md:col-6">
        <label htmlFor="description">Start date</label>
        <Calendar
          className="w-full"
          dateFormat="dd/mm/yy"
          placeholder="Select date"
          value={details.start_date ? new Date(details.start_date) : null}
          onChange={(e: any) => {
            const { value } = e;
            onChange(value, "start_date");
          }}
          showTime
          showButtonBar
          showIcon
        />
      </div>
      <div className="field col-12 md:col-6">
        <label htmlFor="description">End date</label>
        <Calendar
          className="w-full"
          placeholder="Select date"
          value={details.end_date ? new Date(details.end_date) : null}
          onChange={(e: any) => {
            const { value } = e;
            onChange(value, "end_date");
          }}
          showTime
          showButtonBar
          showIcon
        />
      </div>

      <div className="grid field col-12">
        <div className="field col-12 md:col-6">
          <label htmlFor="is_min_order_value">Apply order value</label>
          <br />
          <Checkbox
            onChange={(e) => onChange(e.checked, "is_order_value")}
            checked={details.is_order_value}
          />
        </div>
        <div className="field col-12 md:col-6">
          <label htmlFor="order_value">
            Order Value ({isPercent ? "Tickets" : "Amount"})
          </label>
          <InputNumber
            id="order_value"
            value={details.order_value}
            onChange={(e) => onChange(e.value, "order_value")}
            required
          />
        </div>
      </div>

      <div className="grid field col-12">
        <div className="field col-12 md:col-6">
          <label htmlFor="is_min_order_value">Apply min order value</label>
          <br />
          <Checkbox
            onChange={(e) => onChange(e.checked, "is_min_order_value")}
            checked={details.is_min_order_value}
          />
        </div>
        <div className="field col-12 md:col-6">
          <label htmlFor="min_order_value">
            Min Order Value ({isPercent ? "Tickets" : "Amount"})
          </label>
          <InputNumber
            id="min_order_value"
            value={details.min_order_value}
            onChange={(e) => onChange(e.value, "min_order_value")}
            required
          />
        </div>
      </div>

      <div className="grid field col-12">
        <div className="field col-12 md:col-6">
          <label htmlFor="is_max_order_value">Apply max order value</label>
          <br />
          <Checkbox
            onChange={(e) => onChange(e.checked, "is_max_order_value")}
            checked={details.is_max_order_value}
          />
        </div>
        <div className="field col-12 md:col-6">
          <label htmlFor="min_order_value">
            Max Order Value ({isPercent ? "Tickets" : "Amount"})
          </label>
          <InputNumber
            id="max_order_value"
            value={details.max_order_value}
            onChange={(e) => onChange(e.value, "max_order_value")}
            required
          />
        </div>
      </div>

      <div className="grid field col-12">
        <div className="field col-12 md:col-4">
          <label htmlFor="is_frame_time">Apply frame time</label>
          <br />
          <Checkbox
            onChange={(e) => onChange(e.checked, "is_frame_time")}
            checked={details.is_frame_time}
          />
        </div>
        <div className="field col-12 md:col-4">
          <label htmlFor="start_time">Start Time</label>
          <Dropdown
            filter
            value={details.start_time}
            options={HOURS.map((h) => ({
              label: h,
              value: h,
            }))}
            onChange={(e) => onChange(e.value, "start_time")}
            optionLabel="label"
            placeholder="Select start time"
          />
        </div>
        <div className="field col-12 md:col-4">
          <label htmlFor="end_time">End Time</label>
          <Dropdown
            filter
            value={details.end_time}
            options={HOURS.map((h) => ({
              label: h,
              value: h,
            }))}
            onChange={(e) => onChange(e.value, "end_time")}
            optionLabel="label"
            placeholder="Select end time"
          />
        </div>
      </div>
    </div>
  );
};

export default forwardRef(Details);
