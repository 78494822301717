/* eslint-disable react-hooks/exhaustive-deps */
import VUpload from "components/v-upload";
import { MasterContext } from "contexts/MasterContext";
import { Button } from "primereact/button";
import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import { ToggleButton } from "primereact/togglebutton";
import { Toast } from "primereact/toast";
import {
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import SettingService from "services/settings";
import { showToast } from "utils/common";
import { SETTING_TYPES } from "utils/enum";

const Row = ({
  children,
  className,
}: {
  children: ReactNode;
  className?: string;
}) => {
  return (
    <div
      className={`flex flex-column w-full ${className}`}
      style={{
        rowGap: 12,
      }}
    >
      {children}
    </div>
  );
};

const SystemPage = () => {
  const { auth } = useContext(MasterContext);
  const toast = useRef(null);

  const [settingData, setSettingData] = useState<any>(null);
  const [systemForm, setSystemForm] = useState({
    id: "",
    favicon: "",
    thumbnail: "",
    title: "",
    description: "",
    domain: "",
    tax: 0,
    taamount: 0,
    logo: "",
    maintenance: false,
    encourage: "",
  });

  const [isLoading, setIsLoading] = useState(false);

  const onChangeFormField = (
    value: string | number | boolean,
    fieldName: string
  ) => {
    setSystemForm((prev) => ({
      ...prev,
      [fieldName]: value,
    }));
  };

  const handleSettingSystem = () => {
    setIsLoading(true);
    const actionFnc = settingData
      ? SettingService.updateSetting
      : SettingService.createSetting;
    actionFnc({
      body: {
        type: SETTING_TYPES.SYSTEMS,
        value: systemForm,
      },
    })
      .then(() => {
        handleGetSettingSystem();
        showToast(toast, "success", "Updated Successfully.");
      })
      .finally(() => setIsLoading(false));
  };

  const handleSetFormValue = useCallback((data: any) => {
    if (data) {
      setSystemForm({
        ...data.value,
        maintenance:
          data.value.maintenance === "true" || data.value.maintenance === true,
      });
    }
  }, []);

  const handleGetSettingSystem = () => {
    SettingService.getSettingsByType({
      type: SETTING_TYPES.SYSTEMS,
    }).then((resp) => {
      setSettingData(resp.setting);
      handleSetFormValue(resp.setting);
    });
  };

  useEffect(() => {
    if (auth) {
      handleGetSettingSystem();
    }
  }, [auth, handleSetFormValue]);

  return (
    <Row className="card">
      <Toast ref={toast} />
      <div className="flex flex-column md:flex-row w-full">
        <Row className="w-full md:w-6">
          <label htmlFor="">Logo</label>
          <VUpload
            objectFile={systemForm.logo}
            setObjectFile={(o) => onChangeFormField(o, "logo")}
            fileType="image"
            imageStyle={{
              objectFit: "cover",
              borderRadius: 8,
              height: "fit-content",
            }}
            isObject={true}
          />
        </Row>

        <Row className="w-full md:w-6">
          <label htmlFor="">Site Icon</label>
          <VUpload
            urlFile={systemForm.favicon}
            setUrlFile={(url) => onChangeFormField(url, "favicon")}
            fileType="image"
            imageStyle={{
              objectFit: "cover",
              borderRadius: 8,
              width: "fit-content",
              // height: "fit-content",
            }}
          />
        </Row>
        <Row className="w-full md:w-6">
          <label htmlFor="">Thumbnail</label>
          <VUpload
            urlFile={systemForm.thumbnail}
            setUrlFile={(url) => onChangeFormField(url, "thumbnail")}
            fileType="image"
            imageStyle={{
              objectFit: "cover",
              // borderRadius: 8,
              width: "auto",
              height: "auto",
              maxHeight: 300,
              maxWidth: "100%",
            }}
          />
        </Row>
      </div>
      <Row>
        <label htmlFor="">Title</label>
        <InputText
          value={systemForm.title}
          onChange={(e) => onChangeFormField(e.target.value, "title")}
          placeholder="Title"
        />
      </Row>
      <Row>
        <label htmlFor="">Description</label>
        <InputText
          value={systemForm.description}
          onChange={(e) => onChangeFormField(e.target.value, "description")}
          placeholder="Description"
        />
      </Row>
      <Row>
        <label htmlFor="">Domain</label>
        <InputText
          value={systemForm.domain}
          onChange={(e) => onChangeFormField(e.target.value, "domain")}
          placeholder="Domain"
        />
      </Row>
      <Row>
        <label htmlFor="">Tax</label>
        <InputNumber
          value={systemForm.tax}
          onChange={(e) => onChangeFormField(e.value, "tax")}
          placeholder="0"
          prefix="%"
        />
      </Row>
      <Row>
        <label htmlFor="">TA amount</label>
        <InputNumber
          value={systemForm.taamount}
          onChange={(e) => onChangeFormField(e.value, "taamount")}
          placeholder="0"
          prefix="%"
        />
      </Row>
      <Row>
        <label htmlFor="">Encourage</label>
        <InputText
          value={systemForm.encourage}
          onChange={(e) => onChangeFormField(e.target.value, "encourage")}
          placeholder="encourage"
        />
      </Row>

      <Row>
        <label htmlFor="maintenance">Maintenance</label>
        <ToggleButton
          id="maintenance"
          checked={systemForm.maintenance}
          onChange={(e) => onChangeFormField(e.value, "maintenance")}
          onLabel="Maintenance Mode ON"
          offLabel="Maintenance Mode OFF"
        />
      </Row>

      <div
        className="flex align-items-center justify-content-end w-full mt-4"
        style={{
          columnGap: 12,
        }}
      >
        <Button
          className="p-button-sm p-button-danger"
          onClick={() => handleSetFormValue(settingData)}
        >
          Cancel
        </Button>
        <Button
          className="p-button-sm p-button-success"
          loading={isLoading}
          onClick={handleSettingSystem}
        >
          Save
        </Button>
      </div>
    </Row>
  );
};

export default SystemPage;
