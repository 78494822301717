export enum LEVEL_STATUS {
  APPROVED = "approved",
  PENDING = "pending",
  CANCEL = "cancel",
}

export enum PARTICIPANT_TYPE {
  TASK = "task",
  CAMPAIGN = "campaign",
}

export enum PARTICIPANT_STATUS {
  COMPLETED = "completed",
  PENDING = "pending",
  ERROR = "error",
}

export enum COMMON_STATUS {
  ENABLE = "enable",
  DISABLE = "disable",
}
export enum STAGE_TYPE {
  FRONT = "front",
  BEHIND = "behind",
}

export enum LOCATION_TYPES {
  COUNTRY = "country",
  PROVINCE = "province",
  DISTRICT = "district",
  WARD = "ward",
}

export enum MENU_TYPE {
  INFO = "info",
  NAVIGATE = "navigate",
  INTRODUCE = "introduce",
}

export enum MENU_STATUS {
  ENABLE = "enable",
  DISABLE = "disable",
}

export enum MENU_POSITION {
  HEADER = "header",
  FOOTER = "footer",
}

export enum MENU_TYPE_TREE {
  PARENT = "parent",
  CHILDREN = "children",
}

export enum CATEGORY_STATUS {
  ENABLE = "enable",
  DISABLE = "disable",
}

export enum ADS_BANNER_STATUS {
  ENABLE = "enable",
  DISABLE = "disable",
}

export enum EVENT_STATUS {
  COMING_SOON = "coming_soon",
  HAPPENING = "happening",
  HAPPENED = "happened",
}

export enum TYPE_SEAT {
  NORMAL = "normal",
  ODD_EVEN = "odd-even",
  EVEN_ODD = "even-odd",
}

export enum EOrderPaymentStatus {
  SUCCESS = "success",
  FAIL = "fail",
  PENDING = "pending",
}

export enum TAB_TYPES {
  NEWS = "news",
  NOTIFICATIONS = "notifications",
  ARTISTS = "artists",
  SHOWS = "show",
  SERVICES = "services",
}

export enum TICKET_STATUS {
  NOT_CHECKED_IN = "not_checked_in",
  CHECKED_IN = "checked_in",
  EXPIRED = "expired",
}

export enum SETTING_TYPES {
  SYSTEMS = "systems",
  EMAIL_TEMPLATES = "email_templates",
  ONEPAY = "onepay",
}

export enum PUBLISH_STATUS {
  PUBLISHED = "published",
  DRAFT = "draft",
}


export enum VOUCHER_TYPE {
  PERCENT = "percent",
  AMOUNT = "amount",
  TICKET = "ticket",
  PAY = "pay",
}